import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/Layout'
import Features from '../components/Features'
import Testimonials from '../components/Testimonials'
import BlogRoll from '../components/BlogRoll'

export const IndexPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  //mainpitch,
  description,
  intro,
  testimonials,
}) => (
  
    <div className="margin-bottom-0" style={{marginTop:'0.5rem'}}>
        <div
          className="full-width-image"
          style={{
            backgroundImage: `url(${
              !!image.childImageSharp
                ? image.childImageSharp.fluid.src
                : image
            })`,
            backgroundPosition: `top left`,
            backgroundAttachment: `fixed`,
          }}
    >
  <div style={{
        display: 'flex',
        height: '150px',
        lineHeight: '1',
        justifyContent: 'space-around',
        alignItems: 'left',
        flexDirection: 'column' }}>
      <h1
        className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
        style={{
          backgroundColor: 'rgb(51,51,153)',
          color: 'white',
          lineHeight: '1',
          padding: '0.2em',
          boxShadow: 'rgb(51,51,153) -1.3rem 0px 0px',
        }}
      >
        {title}
      </h1>
      <h3 className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
          style={{
            boxShadow: 'rgb(51,51,153) 0.5rem 0px 0px, rgb(51,51,153) -0.5rem 0px 0px',
            backgroundColor: 'rgb(51,51,153)',
            color: 'white',
            lineHeight: '1',
            padding: '0.25em'
          }}
      >
        {subheading}
      </h3>
      </div>
    </div>
  <section className="section section--gradient" style={{marginTop:0,paddingTop:0}}>
    <div className="container">
      <div className="section">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="content">
              
              <div className="columns">
                <div className="column is-12">
                  <h3 className="has-text-weight-semibold is-size-2">
                    {heading}
                  </h3>
                  <p>{description}</p>
                </div>
              </div>
              <div className="columns" style={{marginBottom:0,marginTop:'0.3rem'}}>
                <div className="column is-12">
                  <h3 className="has-text-weight-semibold is-size-2">
                    {intro.svcheading}
                  </h3>
                </div>
              </div>
              <Features gridItems={intro.blurbs} showLinks={true} />
              <Testimonials testimonials={testimonials} />
              <div className="column is-12" style={{marginTop:'1.5em'}}>
                  <h3 className="has-text-weight-semibold is-size-2">
                    Latest News
                  </h3>
                  <BlogRoll postLimit="2"/>
                  <div className="column is-12 has-text-centered">
                  <Link className="btn" to="/news">
                    Read more
                  </Link>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <Helmet>
  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:site" content="@wesdata" />
  <meta name="twitter:creator" content="@wesdata" />
  <meta name="twitter:title" content="WesData LLC" />
  <meta name="twitter:description" content="Product feed management" />
  <meta name="twitter:image" content="https://wesdata.com/img/screen-stats-logo.png" />
  <meta property="og:type" content="article" />
  <meta property="og:title" content="WesData LLC" />
  <meta property="og:description" content="Product feed management" />
  <meta property="og:image" content="https://wesdata.com/img/screen-stats-logo.png" />
  <meta property="og:url" content="https://wesdata.com" />
  </Helmet>
  </div>
)

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    svcheading: PropTypes.string,
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
        testimonials={frontmatter.testimonials}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
query IndexPageTemplate {
  markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        description
        intro {
          svcheading
          blurbs {
            icon
            title
            link
            text
          }
        }
        testimonials {
          author
          quote
        }
      }
    }
  }
`
